import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import { Map, latLng } from "leaflet";
import bbox from "@turf/bbox";
import {
  PropsWithChildren,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useCallback,
  useState,
} from "react";
import L from "leaflet";
import "leaflet.zoomslider/src/L.Control.Zoomslider";
import "leaflet.zoomslider/src/L.Control.Zoomslider.css";
import "./AgMap.scss";

export const icons = {
  property: {
    white: L.icon({
      iconUrl: "/assets/pins/white.svg",
      iconSize: [48, 48],
      iconAnchor: [24, 48],
    }),
    blue: L.icon({
      iconUrl: "/assets/pins/blue.svg",
      iconSize: [48, 48],
      iconAnchor: [24, 48],
    }),
    yellow: L.icon({
      iconUrl: "/assets/pins/yellow.svg",
      iconSize: [48, 48],
      iconAnchor: [24, 48],
    }),
    orange: L.icon({
      iconUrl: "/assets/pins/orange.svg",
      iconSize: [48, 48],
      iconAnchor: [24, 48],
    }),
    red: L.icon({
      iconUrl: "/assets/pins/red.svg",
      iconSize: [48, 48],
      iconAnchor: [24, 48],
    }),
  },
};

type ZoomControlOptions = "default" | "slider" | "none";

export type AgMapProps = {
  style?: React.CSSProperties;
  flyTo?: { latLng: L.LatLngExpression; zoom: number; duration: number };
  geojsonFocus?: GeoJSON.FeatureCollection;
  nonInteractive?: boolean;
  onMapClick?: (e: L.LeafletMouseEvent) => void;
  zoomControl?: ZoomControlOptions;
};
const originBounds: L.LatLngBoundsExpression = [
  [-37.9, 140.6],
  [-27.7, 154.0],
];
const maxBounds: L.LatLngBoundsExpression = [
  [-37.6, 140.8],
  [-27.5, 153.8], // a bit more north, to allow space for the in-map address search
];
const maxZoom = 15;

export type ForwardedMapRef = {
  canFlyTo: () => boolean;
  flyTo: Map["flyTo"];
};

export const AgMap = forwardRef<ForwardedMapRef, PropsWithChildren<AgMapProps>>(
  (
    {
      children,
      flyTo,
      style,
      geojsonFocus,
      nonInteractive,
      onMapClick,
      zoomControl = "none",
    },
    forwardedRef
  ) => {
    const [thisMap, setThisMap] = useState<{ current: Map | undefined }>({
      current: undefined,
    });

    const thisMapRef = useCallback<(map: Map) => void>((map) => {
      setThisMap({ current: map });
    }, []);

    useImperativeHandle(
      forwardedRef,
      () => ({
        canFlyTo: () => !!thisMap?.current?.flyTo,
        flyTo: (...args) => {
          return thisMap.current!.flyTo(...args);
        },
      }),
      []
    );

    const r = useRef<NodeJS.Timeout>();

    const newTimeout = (cb: () => void, ms: number) => {
      if (r.current) {
        clearTimeout(r.current);
      }
      r.current = setTimeout(cb, ms);
    };

    useEffect(() => {
      if (thisMap?.current) {
        try {
          thisMap.current.invalidateSize();
          if (nonInteractive) {
            thisMap.current.dragging.disable();
            thisMap.current.touchZoom.disable();
            thisMap.current.doubleClickZoom.disable();
            thisMap.current.scrollWheelZoom.disable();
            thisMap.current.boxZoom.disable();
            thisMap.current.keyboard.disable();
          }
          thisMap.current.on("click", (e) => {
            if (onMapClick) {
              onMapClick(e);
            }
          });

          newTimeout(() => {
            if (!thisMap?.current) return;
            thisMap.current.fitBounds(originBounds, { padding: [20, 20] });
            thisMap.current.invalidateSize();
          }, 100);
        } catch (e) {
          console.error(e);
        }
      }
    }, [thisMap.current]);

    useEffect(() => {
      // don't do fly to if a geojsonfocus bounds has been passed in
      if (thisMap?.current && flyTo !== undefined && !geojsonFocus) {
        try {
          // double check that this is a valid latLng object
          const flyToLatLng = latLng(flyTo.latLng);
          if (!isNaN(flyToLatLng.lat) && !isNaN(flyToLatLng.lng)) {
            thisMap.current.flyTo(flyTo.latLng, flyTo.zoom, {
              duration: flyTo.duration,
            });
          }
        } catch (e) {
          console.error("Invalid flyTo latLng provided");
          console.error(flyTo.latLng);
          console.error(e);
        }
      }
    }, [thisMap.current, flyTo, geojsonFocus]);

    useEffect(() => {
      setTimeout(
        () => {
          if (geojsonFocus) {
            newTimeout(() => {
              if (!thisMap?.current) return;
              thisMap.current.invalidateSize();
              thisMap.current.invalidateSize();
              const bb = bbox(geojsonFocus);
              thisMap.current.fitBounds(
                [
                  [bb[1], bb[0]],
                  [bb[3], bb[2]],
                ],
                { padding: [20, 20] }
              );
            }, 1);
          }
        },
        thisMap?.current ? 5 : 0
      );
    }, [thisMap.current, geojsonFocus]);

    useEffect(() => {
      const Control = L.Control as unknown as any;
      const zoomSliderControl = new Control.Zoomslider({
        position: "topright",
      });

      if (thisMap?.current) {
        if (zoomControl === "slider") {
          thisMap.current.addControl(zoomSliderControl);
          thisMap.current.setMaxZoom(maxZoom);
          thisMap.current.setMinZoom(thisMap.current.getBoundsZoom(maxBounds));
        } else {
          thisMap.current.removeControl(zoomSliderControl);
        }
      }

      return () => {
        thisMap?.current?.removeControl(zoomSliderControl);
      };
    }, [zoomControl, L.Control, thisMap.current]);

    return (
      <MapContainer
        attributionControl={false}
        zoomControl={false}
        style={style}
        maxZoom={maxZoom}
        zoomSnap={0.1}
        maxBounds={maxBounds}
        bounds={originBounds}
        ref={thisMapRef}
      >
        {zoomControl === "default" && <ZoomControl position="topright" />}
        <TileLayer
          // url="https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Base_Map/MapServer/tile/{z}/{y}/{x}"
          url={`https://server.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}`}
          maxZoom={maxZoom}
        />
        {/* <TileLayer
        url={`https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Reference/MapServer/tile/{z}/{y}/{x}`}
        maxZoom={15}
      /> */}
        {children}
      </MapContainer>
    );
  }
);
